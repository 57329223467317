<template>
	<panel :show="show" class="wmaReply-section" @close="close">
		<template #title>
			WMA Reply
		</template>
		<template>
			<div class="wmaReply__title-icon">
				<ReplyPanelIcon />
			</div>
			<form class="panel-form" @submit.prevent="onFormSubmit">
				<div class="form-group">
					<label class="form-label form-group-title-new">
						Message
					</label>
					<textarea
						v-model="content"
						placeholder="Enter reply here..."
						class="textarea"
						@input="checkWordCount"
					></textarea>
					<div
						v-if="textAreaErrorMessage"
						class="text-red-500 text-sm mt-2"
					>
						{{ textAreaErrorMessage }}
					</div>
				</div>
				<div class="form-group form-button reply-section">
					<button
						ref="last"
						:disabled="busy"
						type="submit"
						class="action-button"
						@keydown.tab="onLastElementTab"
						@click="onFormSubmit"
					>
						Send
					</button>
				</div>
			</form>
		</template>
	</panel>
</template>
<script>
import Panel from './Panel'
import PanelMixin from '@/mixins/PanelMixin'
import { mapActions } from 'vuex'
import ReplyPanelIcon from '@/components/Icons/ReplyPanelIcon.vue'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		ReplyPanelIcon,
		Panel,
	},

	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current active call.
		 *
		 * @return {Object}
		 */
		call() {
			return this.findByCallSid(this.sid)
		},

		...mapActions({
			findByCallSid: 'calls/findBySid',
		}),
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Method to count the words in the content and perform validation.
		 */
		checkWordCount() {
			this.wordCount = this.content.split(/\s+/).filter(Boolean).length

			if (!this.content.trim()) {
				this.textAreaErrorMessage = 'Reply is required.'
			} else if (this.wordCount > 100) {
				this.textAreaErrorMessage =
					'Reply should consist of no more than 100 words.'
			} else {
				this.textAreaErrorMessage = ''
			}

			console.log('error message', this.textAreaErrorMessage)
		},

		/**
		 * Handle the form submit event.
		 *
		 * @return {void}
		 */
		async onFormSubmit() {
			this.busy = true
			this.wordCount = this.content.split(/\s+/).filter(Boolean).length

			if (!this.content.trim()) {
				this.textAreaErrorMessage = 'Reply is required.'
			}

			// Check if there's any validation error
			if (this.textAreaErrorMessage) {
				this.busy = false // Re-enable the button before throwing the error
				this.$alert.error(this.textAreaErrorMessage) // This stops the function execution
				return
			}

			try {
				// Prepare the payload for the API request
				const payload = {
					action: 'wma_reply',
					data: this.content, // The reply content from the form
				}

				// API URL for sending the reply
				const apiUrl = `${this.baseUrl}/api/v3/calls/${this.sid}/response`

				const response = await fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${this.$store.state.auth.session.access_token}`,
					},
					body: JSON.stringify(payload),
				})

				// Handle the response
				if (!response.ok) {
					const errorResponse = await response.json()
					throw new Error(
						errorResponse.message || 'Failed to send WMA reply'
					)
				}

				// Success
				this.$alert.success('WMA reply sent successfully')

				// Close the panel after a successful submission
				this.close()
			} catch (error) {
				// Handle error
				this.$alert.error(
					error.message ||
						'An unexpected error occurred while sending the reply.'
				)
			} finally {
				// Ensure the submit button is re-enabled
				this.busy = false
			}
		},

		/**
		 * Close the panel and reset the content.
		 */
		close() {
			this.content = '' // Clear content when panel is closed.
			;(this.textAreaErrorMessage = ''), this.$emit('close')
		},
	},

	/**
	 * The component's mergeable mixins.
	 *
	 * @type {Array}
	 */
	mixins: [PanelMixin],

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'WmaNoteReply',

	/**
	 * The component's inherited properties.
	 *
	 * @type {Object}
	 */
	props: {
		/**
		 * Determine if the call is a paging call.
		 *
		 * @type {Boolean}
		 */
		// isPage: {
		// 	type: Boolean,
		// },

		/**
		 * The current call's SID to add the note to.
		 *
		 * @type {String}
		 */
		sid: {
			required: true,
			validator: prop => typeof prop === 'string' || prop === null,
		},
	},

	/**
	 * Get the component's initial state.
	 *
	 * @return {Object}
	 */
	data() {
		return {
			busy: false,
			content: '',
			baseUrl: process.env.VUE_APP_API_URL,
			textAreaErrorMessage: '',
		}
	},
}
</script>
